<template>
    <div>
       <form class="api-form api-form__wrapper" id="api-form-projectstart" v-on:submit.prevent>
          
           <div class="api-form__item api-form__item--col6">
                <label for="name">Dein Name:</label>
                <input type="text" placeholder="Max Mustermann" required name="name" />
           </div>
           <div class="api-form__item api-form__item--col6">
                <label for="company">Dein Hausname / Firma:</label>
                <input type="text" placeholder="Munstermannsfirma" name="company"/>
           </div>
           <div class="api-form__item api-form__item--col6 api-form__item--radio-area">
                <label><strong>Auftragsart</strong></label>
                <input type="radio" id="reparatur" name="auftragsart" value="Reparatur" required>
                <label for="auftragsart">Reparaturauftrag</label>
                <input type="radio" id="newproject" name="auftragsart" value="Neues Projekt">
                <label for="auftragsart">Neues Projekt</label>
           </div>
           <div class="api-form__item api-form__item--col6">
                <label for="street">Deine Straße und Hausnummer:</label>
                <input type="text" placeholder="Mustermannweg 1" required name="street" />
           </div>
           <div class="api-form__item api-form__item--col6">
                <label for="plz">Deine Postleitzahl:</label>
                <input type="text" placeholder="21335" required name="plz"/>
           </div>
           <div class="api-form__item api-form__item--col6">
                <label for="place">Ort:</label>
                <input type="text" placeholder="Ort" required name="place"  />
           </div>
           <div class="api-form__item api-form__item--col6">
                <label for="email">Deine E-Mail-Adresse:</label>
                <input type="email" placeholder="mustermann@mustermann.de" required name="email" />
           </div>
           <div class="api-form__item api-form__item--col12">
                <label for="message">Deine Nachricht bzw. Notiz:</label>
                <textarea placeholder="Nachricht" name="message" />
           </div>
            <div class="api-form__item api-form__item--col12">
                <p>Mit dem Absenden bestätigst du, dass du unsere <a href="/datenschutz/" target="_blank">Datenschutzbestimmungen</a> zur Kenntnis genommen hast.</p>
           </div>
           <div class="api-form__item api-form__item--col12">
               <input type="submit"  @click="setUpFormdata" class="api-form__cta" vale="Senden" />
           </div>
        </form>
        <p v-if="errorMSG">{{errorMSG}}</p>
        
        

    </div>
    
</template>
<script>


export default {
  name: 'Apiformpreispost',
  data: function () {
        return {
            waitanimation: false, 
            name: "",
            api: "",
            errorMSG:"",
            formdata: {
              
                
            }
        }
    },
  methods: {
    
    setUpFormdata(){
        //event.preventDefault();
        let apiForm = document.getElementById('api-form-projectstart');

        let apiFormClientName = apiForm.querySelector('input[name="name"]');
        let apiFormCompanyName = apiForm.querySelector('input[name="company"]');
        let apiFormAuftragsartReparatur = document.getElementById('reparatur');
        let apiFormAuftragsartAuftrag = document.getElementById('newproject');
        let apiFormAuftragsart = apiForm.querySelector('input[name="auftragsart"]');
        let apiFormClientStreet = apiForm.querySelector('input[name="street"]');
        let apiFormClientbillingZipcode = apiForm.querySelector('input[name="plz"]');
        let apiFormClientbillingCity = apiForm.querySelector('input[name="place"]');
        let apiFormClientbillingEmail = apiForm.querySelector('input[name="email"]');
        let apiFormnoteContent = apiForm.querySelector('textarea[name="message"]');

     

        this.formdata.clientName = apiFormClientName.value;
        if(apiFormCompanyName.value != ""){
            this.formdata.name = 'Auftrag von der Firma: ' + apiFormCompanyName.value;
            this.formdata.billingName = apiFormCompanyName.value
            
        } else {
            this.formdata.name = 'Auftrag von der Person: ' + apiFormClientName.value;
             this.formdata.billingName = apiFormClientName.value;
        }
        if (apiFormAuftragsartReparatur.checked == true){
            this.formdata.parentProject = "3D4D538D-7254-A3C6-2191-35EA03AF9185"
        } 
        if (apiFormAuftragsartAuftrag.checked == true){
            this.formdata.parentProject = "B0097365-7E5B-FDD4-B958-6B0CD3C67CC8"
        }
         
        this.formdata.street = apiFormClientStreet.value;
        this.formdata.zipcode = apiFormClientbillingZipcode.value;
        this.formdata.city = apiFormClientbillingCity.value;
        this.formdata.billingEmail = apiFormClientbillingEmail.value;
        this.formdata.noteContent = apiFormnoteContent.value;
        
        


    if(apiFormClientName.value!="" && apiFormAuftragsart.value!="" && apiFormClientStreet.value!= "" && apiFormClientbillingZipcode.value!="" && apiFormClientbillingCity.value!="" && apiFormClientbillingEmail.value!= ""){
        this.postmanscritp();
        this.errorMSG = null;
    } else {
        this.errorMSG = "Bitte füllen SIe alle Felder aus!"
    }

    },
    pushdata($event){
        var el = $event.target.getAttribute("name");
        this.formdata.street= el;   
        console.log( el);
     },
    postmanscritp(){
        var myHeaders = new Headers();
        myHeaders.append("X-CN-API-Key", "81ef0ab9-3e51-43b6-9fbb-1c0ec57c8b31");
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Credentials", "true");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(this.formdata);

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("https://europe-west1-craftnote-live.cloudfunctions.net/api/v1/projects", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }
    
  }
    
}

        
</script>
<style lang="scss">
    .api-form{
        * {
            box-sizing: border-box;
        }
        &__wrapper{
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }
        &__item{
            margin: 1%;
            text-align: left;
            width: 100%;
            margin-bottom: 2rem;
            label{
                display: block;
                width: 100%;
                font-weight: 700;
               margin-bottom: 5px;
            }
            input, textarea{
                width: 100%;
                border: 1px solid grey;
                padding: 1rem;
            }
            textarea{
                min-height: 5rem;
            }
            &--col6{
                @media only screen and (min-width: 768px){
                    width: 48%
                }
            }
            &--col12{
                @media only screen and (min-width: 768px){
                    width: 100%;
                }
            }
            &--radio-area{
                label, input{
                    width: auto;
                    display: inline-block;
                    &:first-child{
                        width: 100%;
                        margin-bottom: 0.5rem;
                    }
                }
                input{
                    margin-left: 1rem;
                    margin-right: 0.25rem;
                   
                }
            }
        }
        &__cta{
            background-color: #df002b;
            border: 1px solid #df002b!important;
            color: #fff;
            transition: background-color 0.15s linear;
            display: inline-block;
            width: auto!important;
            font-size: 14px;
            &:hover{
                background-color: #fff;
                color: #df002b;
            }
        }
    }
</style>


